import {  getCurrentUser, tlang, WMEventSourceClient } from '@softtech/webmodule-components';
import { fireQuickInformationToast } from '../toast-away';
import { html } from 'lit';
import { tlangMarkdown } from '../components/markdown';
import { DevelopmentError } from '../development-error';



export class WMEventSource {
  public static quote = 'quote';
  public static order = 'order';
  public static projectDocument = 'project-document';
  public static projectDocumentDel = 'project-document-delete';
  public static project = 'project';
  public static projectResource = 'project-resource-reference';
  public static projectResourceDel = 'project-resource-reference-delete';
  public static v6configServiceStatus = 'v6-service-status';
  public static afterConnect() {
    const user = getCurrentUser();
    if (!user) throw new DevelopmentError('User not connected');
    const dontShow = true; // localStorage.getItem(`source-event-notify:${userId}:v3`) === 'hide';
    if (dontShow) return;
    const clickEvent = () => {
      localStorage.setItem(`source-event-notify:${user.id}:v3`, 'hide');
    };
    const infoTemplate = html`<div>
      ${tlangMarkdown`${'ref:eventsource-connected'}**You are now receiving
            live updates from server as they happen.**
            
            Your !!quote!! and !!project!! will refresh as data is updated on the server.
            `}
      <button class="btn btn-primary no-btn p-0 text-decoration-none fs-6" data-bs-dismiss="toast" @click=${clickEvent}>
        ${tlang`Don't show again`}
      </button>
    </div>`;

    fireQuickInformationToast(infoTemplate, 60000);
  }
  public static getInstance(): WMEventSourceClient {
    return WMEventSourceClient.getInstance();
  }
}
