//Dealer Franchisee subclass views
import {
  QuoteState,
  ResultGetQuoteSummary,
  ViewQuoteSummary,
  ViewQuoteTotalByState
} from '../../../api/dealer-api-interface-quote';
import { emptyGuid } from '../../../api/guid';
import { NullPromise } from '../../../null-promise';
import { QuoteListView, QuoteSummaryTable, QuoteSummaryTableOptions } from '../../../quotes/views/quote-list-view';
import { IStaleData } from '../../../components/stale-data';
import { QuoteContainer, QuoteContainerManager } from '../../../quotes/data/quote-container';
import { FranchiseeApi } from '../../../api/franchisee-api';
import { getApiFactory } from '../../../api/api-injector';
import {
  defaultStagedThreshold,
  EventCancellation,
  stagedExecution,
  getCurrentUser,
  tlang,
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebmoduleSelectEvent
} from '@softtech/webmodule-components';
import { isEmptyOrSpace, validId } from '../../../components/ui/helper-functions';
import { moneyToTemplateResult } from '../../../components/currency-formatter';
import { getQuoteNumberFormatted, getQuoteStatus } from '../../../quotes/data/quote-helper-functions';
import { html, TemplateResult } from 'lit';
import { getQuoteContainer, getQuoteContainerManager } from '../quote-ui-adapter';
import { cache } from '../../cache/cache-registry';
import { ItemReference } from '../../../cache/definitions/cache-item-reference';
import { QuoteCacheData } from '../../cache/cache-data';
import { ProjectResourceLink } from '../../cache/resource-link';
import { createNewQuote } from '../quote-creator';
import { getSupplier } from '../supplier-selector';
import { EventSnippet } from '../../../components/ui/events';

import { resourceProject } from '../../../projects/ui/launcher';
import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../components/ui/modal-confirmation';
import { resolveURL } from '../../../components/ui/resource-resolver';
import { EventBoolean } from '@softtech/webmodule-data-contracts';
import { userDataStore } from '../../common/current-user-data-store';
import { displaySupplierTACNotApprovedMsg } from '../../../v6config/supplier-services';
import { when } from 'lit/directives/when.js';
import { customElement, state } from 'lit/decorators.js';
import { WMEventSource } from '../../../api/event-source';
import { SSEQuote } from '../../../quotes/data/sse-quote';
import { notificationSignal, staleIcon } from '../../../components/ui/icons/icon-notification-signal';

const numberOfDaysHistory = 30;

@customElement('wm-franchiseequotesummarytable')
class FranchiseeQuoteSummaryTable extends QuoteSummaryTable {
  private projectCache = cache().project;
  private quoteCache = cache().quote;
  private linkCache = cache().projectResourceLink;
  private showAllQuotes = true;
  protected staleData: IStaleData;
  constructor(options: QuoteSummaryTableOptions) {
    super(options);
    //this blocks loading of any data until the first time we want it to occur.

    this.staleData = options.stale ?? { exists: () => false };
    this.sortAscending = false;
  }
  isDataLoadDelayed() {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): WebModuleLitTableColumnDef[] {
    const columns: WebModuleLitTableColumnDef[] = [];
    columns.push({
      title: tlang`%%quote%% No.`,
      fieldName: 'quoteNumber',
      sortable: true,
      isDefaultSortDirectionAscending: false,
      classes: 'colpx-130 colpxmax-130 quote-number',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const quoteSummary = item as ViewQuoteSummary;
        return this.stale(quoteSummary)
          ? `${staleIcon(false)}${this.quoteLink(quoteSummary, getQuoteNumberFormatted(quoteSummary))}`
          : this.quoteLink(quoteSummary, getQuoteNumberFormatted(quoteSummary));
      }
    });

    columns.push(
      ...[
        {
          title: tlang`%%quote%% Title`,
          fieldName: 'title',
          sortable: true,
          classes: 'colpx-400 quote-title',
          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            return this.quoteLink(quoteSummary, quoteSummary.title);
          }
        },
        {
          title: tlang`%%project%% Title`,
          fieldName: 'id',
          sortable: false,
          classes: 'colpx-270 colpxmax-270 quote-project-number',
          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            return this.getProjectLink(quoteSummary.id);
          }
        },
        {
          title: tlang`%%client%%`,
          fieldName: 'quoteCustomerId',
          sortable: false,
          classes: 'colpx-240 colpxmax-240 quote-client-name',
          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            return this.getCustomerLink(quoteSummary.quoteCustomerId);
          }
        },
        {
          title: tlang`%%author%%`,
          fieldName: 'assignedToUserId',
          sortable: false,
          classes: 'colpx-200 colpxmax-200 quote-created-by',
          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            return this.getUserDisplayValue(quoteSummary.assignedToUserId);
          }
        },
        {
          title: tlang`Last Modified`,
          fieldName: 'lastModifiedDate',
          sortable: false,
          classes: 'colpx-180 colpxmax-180 quote-modified-date',
          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            const dt = new Date(quoteSummary.lastModifiedDate ?? '');
            return html`${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
          }
        },
        {
          title: tlang`Status`,
          fieldName: 'state',
          sortable: false,
          classes: 'colpx-130 colpxmax-130 quote-status no-pseudo',

          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            return getQuoteStatus(quoteSummary.state, true);
          }
        },
        {
          title: tlang`Net Amount`,
          fieldName: 'calculatedNetTotal',
          sortable: false,
          classes: 'colpx-110 colpxmax-110 dt-right quote-amount no-pseudo',
          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            return moneyToTemplateResult(quoteSummary.calculatedNetTotal);
          }
        },
        {
          title: html`<webmodule-icon library="fa" name="fas-bars"></webmodule-icon>`,
          fieldName: 'id',
          sortable: false,
          classes: 'colpx-50 colpxmax-50 item-menu',
          displayValue: (_table: WebModuleLitTable, item: unknown) => {
            const quoteSummary = item as ViewQuoteSummary;
            return this.ellipsisMenu(quoteSummary);
          }
        }
      ]
    );
    return columns;
  }
  stale(row: ViewQuoteSummary) {
    return this.staleData.exists(row.id);
  }

  override enableAdvancedFiltering(): boolean {
    return true;
  }

  override advancedFilterTemplate(): TemplateResult {
    const createRadioButton = (id: string, text: string, input: boolean, value: EventBoolean) => {
      return html` <softtech-dui-input-boolean
          id=${id}
          @click=${_ => this.onQuoteAuthorChanged(input)}
          .readonly=${value()}
          .checked=${value()}
        ></softtech-dui-input-boolean>
        <label class="form-check-label" for=${id}> ${text} </label>`;
    };

    return html` <div class="form-check form-check-inline quote-author-filter">
      ${createRadioButton('qte-list-show-all', tlang`All !!quote!!`, true, () => this.showAllQuotes)}
      ${createRadioButton('qte-list-show-mine', tlang`My !!quote!!`, false, () => !this.showAllQuotes)}
    </div>`;
  }

  async onMenuItemSelected(event: WebmoduleSelectEvent, quoteSummary: ViewQuoteSummary) {
    if (event.detail.item.value == 'delete') {
      if (
        await AskConfirmation(
          tlang`Are you sure you'd like to delete the %%quote%%?`,
          confirmationButtons[ConfirmationButtonType.yesNo]
        )
      )
        await this.deleteQuoteItem(quoteSummary);
    } else if (event.detail.item.value == 'copy') {
      await this.copyQuoteItem(quoteSummary);
    }
  }

  ellipsisMenu(quoteSummary: ViewQuoteSummary): TemplateResult {
    return html` <webmodule-dropdown
      placement="bottom-end"
      hoist
      @webmodule-select=${(e: WebmoduleSelectEvent) => this.onMenuItemSelected(e, quoteSummary)}
    >
      <webmodule-icon-button slot="trigger" library="fa" name="fas-bars"></webmodule-icon-button>
      <webmodule-menu>
        ${when(
          !(quoteSummary.state == QuoteState.Draft),
          () => '',
          () =>
            html`<webmodule-menu-item class="action-delete" value="delete">
              <webmodule-icon slot="prefix" library="fa" name="fas-trash"></webmodule-icon>
              Delete
            </webmodule-menu-item>`
        )}

        <webmodule-menu-item class="action-copy" value="copy">
          <webmodule-icon slot="prefix" library="fa" name="fas-copy"></webmodule-icon>
          Copy
        </webmodule-menu-item>
      </webmodule-menu>
    </webmodule-dropdown>`;
  }

  async rowClicked(e: CustomEvent<{ table: WebModuleLitTable; item: ViewQuoteSummary }>): Promise<void> {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.eventHandler(async () => {});
  }

  protected getPreFetched(results: ResultGetQuoteSummary): Promise<void>[] {
    const cachePrefetch = super.getPreFetched(results);

    const updateFromSummaries: ((items: ViewQuoteSummary[]) => Promise<ItemReference<QuoteCacheData>[]>) | undefined = (
      this.quoteCache as any
    ).updateFromSummaries.bind(this.quoteCache);
    if (updateFromSummaries) {
      //this is the optimized efficient method
      const p = updateFromSummaries(results.quoteSummary.results).then(items => {
        const quoteIds = items.map(x => x.id);

        return this.linkCache.preFetch(quoteIds).then(() => {
          const projectIds: string[] = [];
          results.quoteSummary.results.forEach(summary => {
            const projectId = this.linkCache.getLocalData(summary.id)?.projectId ?? emptyGuid;
            if (validId(projectId)) projectIds.push(projectId);
          });
          return this.projectCache.preFetch(projectIds);
        });
      });

      cachePrefetch.push(p);
    } else {
      //less optimized fallback
      const quoteIds = results.quoteSummary.results.map(x => x.id);

      cachePrefetch.push(
        this.quoteCache
          .preFetch(quoteIds)
          .then(() => {
            return this.linkCache.getMany(quoteIds);
          })
          .then((data: ItemReference<ProjectResourceLink>[] | null) => {
            const projectIds = data?.map(x => x.data.projectId) ?? [];
            return this.projectCache.preFetch(projectIds);
          })
      );
    }
    return cachePrefetch;
  }

  protected async onQuoteAuthorChanged(value: boolean) {
    this.showAllQuotes = value;
    this.assignedToUserId = this.showAllQuotes ? null : getCurrentUser()?.id ?? null;

    await this.refreshData();
    this.requestUpdate();
  }
  afterReload() {
    this.dataIsStale = false;
  }

  protected getProjectLink(id: string) {
    if (id == emptyGuid) return '';

    const link = this.linkCache.getLocalData(id);

    if (!link) return '';

    const project = this.projectCache.getLocal(link.projectId);

    return project
      ? html`<a class="project-link" href="${resolveURL(resourceProject, project.id)}" data-projectid="${project.id}"
          >${this.htmlEncode(project.data.projectSummary.title)}</a
        >`
      : '';
  }
}

@customElement('wm-franchiseequotelistview')
export class FranchiseeQuoteListView extends QuoteListView {
  franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
  @state()
  quoteTotals: ViewQuoteTotalByState[] | null = null;
  private staleQuotes: string[] = [];

  async init() {
    await this.updatePageTotals();
    const table = this.pageControl.activePage?.data as QuoteSummaryTable;
    if (table) await table.refreshData(1);
  }

  async doAfterQuoteEdit() {
    await this.updatePageTotals();
  }
  private _eventCancel: EventCancellation = new EventCancellation();
  private _eventQuoteUpdated = stagedExecution({
    threshold: defaultStagedThreshold,
    cancelToken: this._eventCancel,
    eventFinally: () => notificationSignal(false),
    event: async () => {
      this.updatePageTotals();
      this.tables.forEach(t => (t.dataIsStale = true));
      const table = this.pageControl.activePage?.data as QuoteSummaryTable;
      this.staleQuotes = [];
      if (table) {
        await table.refreshData();
        table.dataIsStale = false;
        //  fireQuickInformationToast(tlang`Your Quotes have been refreshed`);
      }
      //reload the page.. we dont know what moved, or where, or what state its in
    },
    eventTriggered: (_data: SSEQuote) => {
      notificationSignal(true);
      //fireQuickInformationToast(tlang`Updates are coming soon`);
    },
    eventActioned: (data: SSEQuote) => {
      if (!this.staleQuotes.includes(data.id)) this.staleQuotes.push(data.id);
      const table = this.pageControl.activePage?.data as QuoteSummaryTable;
      table.refreshData();
      //fireQuickInformationToast(tlang`More Updates are coming, refreshing soon`);
    }
  });

  connectedCallback(): void {
    super.connectedCallback();
    WMEventSource.getInstance().addEventListener(WMEventSource.quote, this._eventQuoteUpdated);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    WMEventSource.getInstance().removeEventListener(WMEventSource.quote, this._eventQuoteUpdated);
  }
  protected createQuoteContainerManager(quoteContainer: QuoteContainer): QuoteContainerManager {
    return getQuoteContainerManager(quoteContainer);
  }

  protected async updatePageTotals() {
    const ownerId = await this.quoteOwnerId();
    const totals = await this.quoteApi.getQuoteTotalsByState({
      quoteOwnerId: ownerId,
      options: [
        {
          state: QuoteState.Draft | QuoteState.Active,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Draft,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Active,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Issued | QuoteState.IssuePending,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Accepted,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Accepted_AssignedToReviewer | QuoteState.SupplierReviewPending,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.SupplierReviewed,
          numberOfDaysHistory: null
        },
        {
          state:
            QuoteState.Approved |
            QuoteState.Rejected |
            QuoteState.Accepted_RefusedBySupplier |
            QuoteState.Cancelled |
            QuoteState.Lapsed,
          numberOfDaysHistory: numberOfDaysHistory
        }
      ]
    });
    if (totals) this.quoteTotals = totals.totals.filter(x => x.quoteOwnerId === ownerId);
    this.pageControl.requestUpdate();
  }

  protected createQuoteContainer(quoteId: string): QuoteContainer {
    return getQuoteContainer(quoteId);
  }

  //this can be overridden in sub class to replace the table with different column/view etc
  protected quoteSummaryTableFactory(options: QuoteSummaryTableOptions): FranchiseeQuoteSummaryTable {
    return new FranchiseeQuoteSummaryTable(options);
  }
  beforeRefresh() {
    this.staleQuotes = [];
  }
  afterRefresh() {
    this._eventCancel.cancel();
  }
  async dispose(): Promise<void> {
    await super.dispose();
    this._eventCancel.cancel();
  }
  protected createSummaryTable(
    title: EventSnippet,
    quoteStates: QuoteState,
    pageFragment: string,
    numberOfDays?: number
  ): QuoteSummaryTable {
    return this.quoteSummaryTableFactory({
      quoteOwnerId: this.quoteOwnerId,
      quoteStates: quoteStates,
      numberOfDaysHistory: numberOfDays,
      customerCache: this.customerCache,
      userProfileCache: this.userProfileCache,
      title: title,
      copyQuoteEvent: async (quoteSummary: ViewQuoteSummary) => {
        await this.copyQuote(quoteSummary);
      },
      deleteQuoteEvent: async (quoteSummary: ViewQuoteSummary) => {
        await this.deleteQuote(quoteSummary);
      },
      pageFragment: pageFragment,
      stale: { exists: (id: string) => this.staleQuotes.includes(id) }
    });
  }

  //override this in a subclass to change the design, and order of the tables
  protected initializeTables(): QuoteSummaryTable[] {
    const title = (caption: string, state: QuoteState, hideTotal?: boolean) => {
      let total = 0;
      let count = 0;
      this.quoteTotals
        ?.filter(x => (state & x.quoteState) === x.quoteState)
        .forEach(x => {
          total += x.total;
          count += x.quoteCount;
        });
      return html`${caption} (${count})${hideTotal ? html`` : moneyToTemplateResult(total)}`;
    };
    const makeTable = (
      titleStr: string,
      state: QuoteState,
      pageFragment: string,
      numberOfDays?: number,
      hideTotal?: boolean
    ) => {
      return this.createSummaryTable(() => title(titleStr, state, hideTotal), state, pageFragment, numberOfDays);
    };

    return [
      makeTable(tlang`All Current`, QuoteState.Draft | QuoteState.Active, 'allCurrent', undefined, true),
      makeTable(tlang`Draft`, QuoteState.Draft, 'draft', undefined, true),
      makeTable(tlang`Active`, QuoteState.Active, 'active', undefined, true),

      makeTable(tlang`Issued`, QuoteState.Issued | QuoteState.IssuePending, 'issued', undefined, true),

      makeTable(tlang`Accepted`, QuoteState.Accepted, 'accepted', undefined, true),
      makeTable(
        tlang`Under Review`,
        QuoteState.Accepted_AssignedToReviewer | QuoteState.SupplierReviewPending,
        'underreview',
        undefined,
        true
      ),
      makeTable(tlang`Reviewed`, QuoteState.SupplierReviewed, 'reviewed', undefined, true),

      makeTable(
        tlang`Archived`,
        QuoteState.Approved |
          QuoteState.Rejected |
          QuoteState.Accepted_RefusedBySupplier |
          QuoteState.Cancelled |
          QuoteState.Lapsed,
        'archived',
        numberOfDaysHistory,
        true
      )
    ];
  }

  protected async createNewQuote(): NullPromise<QuoteContainer> {
    const supplier = await getSupplier();
    if (isEmptyOrSpace(supplier.supplierId)) return null;
    if (!userDataStore.supplierTACApproved(supplier.supplierId)) {
      await displaySupplierTACNotApprovedMsg(supplier, true);
      return null;
    }
    const owner = await this.quoteOwnerId();

    return createNewQuote({ supplierId: supplier.supplierId, owner: owner });
  }
}
