import { ViewClientSummary } from '../../api/dealer-api-interface-client';
import { tlang, WebModuleLitTable, WebModuleLitTableColumnDef } from '@softtech/webmodule-components';
import { ClientSummaryTableBase, ClientSummaryTableBaseOptions } from './client-list-table';
import { customElement } from 'lit/decorators.js';
import { html } from 'lit';

interface ClientSummaryPickerOptions extends ClientSummaryTableBaseOptions {
  onClientSelect?: (clientSummary: ViewClientSummary) => void;
}

@customElement('wm-clientsummarypickerbrowser')
export class ClientSummaryPickerBrowser extends ClientSummaryTableBase {
  onClientSelect?: (clientSummary: ViewClientSummary) => void;
  dataTable: any;

  constructor(options: ClientSummaryPickerOptions) {
    super(options);

    this.onClientSelect = options.onClientSelect;
    this.clickrows = true;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%client%%`,
        fieldName: 'name',
        sortable: true,
        classes: 'colpx-250 client-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`<span class="client-link" data-client-id=${rowItem.name}>${rowItem.name}</span>`;
        }
      },
      {
        title: tlang`Primary %%contact%%`,
        fieldName: 'primaryContactName',
        sortable: true,
        classes: 'colpx-250 client-contact-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          if (rowItem.primaryContactName)
            return html`<span class="contact-link"
                              data-contact-id=${rowItem.primaryContactId}>${rowItem.primaryContactName}</span>`;

          return '';
        }
      },
      {
        title: tlang`%%contact%% Email`,
        fieldName: 'primaryContactEmail',
        classes: 'colpx-250 client-contact-email',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`${rowItem.primaryContactEmail}`;
        }
      },
      {
        title: tlang`Address`,
        fieldName: 'physicalAddress',
        classes: 'colpx-400 client-address',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;
          const physicalAddress = rowItem.physicalAddress;

          if (physicalAddress)
            return html`${[physicalAddress.line1, physicalAddress.locality, physicalAddress.region, physicalAddress.postcode, physicalAddress.country]
              .filter(Boolean)
              .join(', ')}`;

          return ''
        }
      }
    ];
  }

  async rowClicked(e: CustomEvent<{ table: WebModuleLitTable; item: ViewClientSummary }>): Promise<void> {
    e.stopImmediatePropagation();
    e.preventDefault();

    this.eventHandler(async () => {
      if (this.onClientSelect) this.onClientSelect?.(e.detail.item);
    });
  }
}
