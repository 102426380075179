//create a generic global handler for communication errors that go unresolved.

import { ServiceResponseInvalid } from '@softtech/webmodule-data-contracts';
import { ValidationError } from './validation-error';
import {
  setCurrentUser,
  strDuplicateLogin,
  strInvalidClientToken,
  strNetworkUnavailable,
  strUnableToConnectToV6,
  tlang
} from '@softtech/webmodule-components';
import { information } from '../components/ui/modal-option';
import { GlobalSupplierNotification } from '../v6config/supplier-services';
import { showError } from '../components/ui/show-error';
import { showValidations } from '../components/ui/modal-validationhandler';
import { checkAppOutOfDate, outOfDateURL } from '../components/debug';
import { goStaticURL } from '../components/ui/resource-resolver';
import { strInvalidBuildNumber } from './api-communications';
import { V6OfflineCause } from '../quotes/quote-service';

let handlingDuplicateLogin = false;
let handling409 = false;
//this will typically only be things we dont want to happen not considered normal workflow
export const responseHandler = async (response: ServiceResponseInvalid | ValidationError[]) => {
  if (!Array.isArray(response)) {
    if (response.responseError?.message === strInvalidBuildNumber) {
      if (handling409) return;
      handling409 = true;
      try {
        await checkAppOutOfDate();

        await information(
          tlang`${'ref:409-outofdate'}
            # Client Application is out of date

            The server could not process the request because the client application is out of date
            and no longer compatible.

            You may loose any changes you were in the process of doing.

            You will now be redirected and required to refresh this application to continue use.

            We apologize for any inconvenience and reccommend closing the Application overnight
            and when not in-use to reduce the risk of data lost during scheduled updates.
            
            `,
          'Application Server Update'
        );
        goStaticURL(outOfDateURL);
        return;
      } finally {
        handling409 = false;
      }
    } else if (response.responseError?.message === strInvalidClientToken) {
      if (handlingDuplicateLogin) return;
      handlingDuplicateLogin = true;
      try {
        await information(tlang`Your client session is no longer valid. Refreshing Page to attempt recovery`);
        window.location.reload();
      } finally {
        handlingDuplicateLogin = false;
      }
      return;
    } else if (response.responseError?.message === strDuplicateLogin) {
      if (handlingDuplicateLogin) return;
      handlingDuplicateLogin = true;
      try {
        await information(
          tlang`You are being disconnected as your account is logged into another machine (or Browser)`
        );
        await setCurrentUser(null);
      } finally {
        handlingDuplicateLogin = false;
      }
      return;
    } else if (response.responseError?.message === strUnableToConnectToV6) {
      await GlobalSupplierNotification.getInstance().makeOffline(V6OfflineCause.Connection);

      return;
    } else if (response.responseError?.message === 'V6 Service upgrade in progress') {
      await GlobalSupplierNotification.getInstance().makeOffline(V6OfflineCause.Upgrading);
      return;
    } else if (response.responseError?.message === strNetworkUnavailable) {
      await information(
        tlang`${'ref:system-offline-msg'}Cannot communicate with the server. There may be a network outage. please check your internet and try again`,
        tlang`${'ref:system-offline-title'}System OFFLINE`
      );
      return;
    }
    const caption = response.responseTypeCaption;
    const msg = response.responseError?.message ?? '';
    const stack = response.responseError?.stackTrace ?? '';
    console.log(`ServiceResponse Error [${caption}] "${msg}" \n StackTrace:${stack}`);
    await showError(response, () => tlang`Server Error Occurred`);
  } else {
    console.log('Service Response Validation Errors Returned');
    const msg: string[] = [];
    response.forEach(err => {
      const errMsg = `${err.property} -> ${err.message}`;
      msg.push(errMsg);
      console.log(errMsg);
    });
    await showValidations(msg, () => tlang`Validation Issues from Server`);
  }
};
