import { tlang } from '@softtech/webmodule-components';
import { emptyGuid } from '../../api/guid';
import { GraphicalFamilyResourcePicker } from '../../picker/graphical-family-resource-picker';
import { getQuoteSuppliers, QuoteSupplier } from '../../quotes/quote-service';
import { GlobalSupplierNotification } from '../../v6config/supplier-services';
import {
  FamilyResourceTag,
  ResourceFamilyEndpoint,
  ResourceFamilyResults
} from '../../picker/family-resource-picker-data';

export async function getSupplier(): Promise<QuoteSupplier> {
  //force a reload of the supplier list when we want to create quotes, to check the online state of things.
  const suppliers = await getQuoteSuppliers(true);

  //TODO - Handle Multi Supplier with a picker.
  if (suppliers.length == 1) {
    if (!suppliers[0].online) {
      await GlobalSupplierNotification.getInstance(suppliers[0].supplierId).showOfflineMessage();
      return { supplierId: emptyGuid, description: '', online: false, offlineCause: undefined };
    }
    return suppliers[0];
  } else {
    return (
      (await supplierPicker(suppliers)) ?? {
        supplierId: emptyGuid,
        description: '',
        online: false,
        offlineCause: undefined
      }
    );
  }
}
export async function supplierPicker(items: QuoteSupplier[]): Promise<QuoteSupplier | null> {
  let resourceName: string | undefined = undefined;
  resourceName = tlang`%%supplier%%`;
  const endpoint = new QuoteSupplierDataEndpoint(convertQuoteSupplierToFamilyData(items));
  const gp = new GraphicalFamilyResourcePicker(endpoint, {
    resourceName: resourceName,
    sideBar: null,
    show: { tree: false, filter: false, cancelButton: true, selectButton: true },
    hideImages: true,
    modalSize: '',
    title: tlang`Select %%quote%% %%supplier%%`
  });
  await gp.showModal();
  return items.find(x => x.supplierId === gp.selected?.objectReference) ?? null;
}
export class QuoteSupplierDataEndpoint implements ResourceFamilyEndpoint {
  familyData: ResourceFamilyResults;

  constructor(familyData: ResourceFamilyResults) {
    this.familyData = familyData;
  }

  getResourceClass(): number {
    //is not used
    return -1;
  }

  async getData(): Promise<ResourceFamilyResults | null> {
    return this.familyData;
  }

  //incase the filter we have is not the same as what must be provided to the server
  public getResourceImageUrl(_resourceClass: number, _libId: number, _id: number): string {
    return '';
  }

  //incase the filter we have is not the same as what must be provided to the server
  public getFamilyImageUrl(_resourceClass: number, _libId: number, _id: number): string {
    return '';
  }
}
export function convertQuoteSupplierToFamilyData(data: QuoteSupplier[]): ResourceFamilyResults {
  function getDescr(item: QuoteSupplier) {
    return item.online ? item.description : `${item.description} - OFFLINE`;
  }

  return {
    family: {
      name: '',
      libId: 0,
      id: 0,
      families: [],
      items: data.map(x => {
        const rt: FamilyResourceTag = {
          objectReference: x.supplierId,
          code: '',
          libId: -1,
          id: -1,
          description: getDescr(x),
          resourceClassId: -1,
          disabled: !x.online
        };
        return rt;
      })
    }
  };
}
