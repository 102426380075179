import { ClientApi } from '../../api/client-api';
import { ResultBrowseClientSummary, ViewClientSummary } from '../../api/dealer-api-interface-client';
import { EventSnippet } from '../../components/ui/events';
import { tlang, WebModuleLitTable, WebModuleLitTableColumnDef } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { customElement } from 'lit/decorators.js';
import { LitTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/littable-view';
import { html } from 'lit';

export interface ClientSummaryTableBaseOptions {
  title: EventSnippet;
}

@customElement('wm-clientsummarytablebase')
export class ClientSummaryTableBase extends LitTableWrapper<ViewClientSummary> {
  eventTitle: EventSnippet;
  api: ClientApi = getApiFactory().client();
  filter: string | null;

  constructor(options: ClientSummaryTableBaseOptions) {
    super();
    this.eventTitle = options.title;
    this.filter = null;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewClientSummary>> {
    const results = await this.api.browseClientSummary({
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      sortField: request.sortField,
      sortAsc: !request.sortAsc,
      clientOwnerId: userDataStore.clientOwnerId,
      filter: this.filter
    });
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    await this.doPreFetching(results);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- It is not null

    return results.clientSummary;
  }

  async doPreFetching(results: ResultBrowseClientSummary) {
    await Promise.all(this.getPreFetched(results));
  }

  enableFiltering(): boolean {
    return true;
  }

  updateFilter(_searchTerm: string | null) {
    this.filter = _searchTerm;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%client%%`,
        fieldName: 'name',
        sortable: true,
        classes: 'colpx-250 client-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`${rowItem.name}`;
        }
      },
      {
        title: tlang`Primary %%contact%%`,
        fieldName: 'primaryContactName',
        sortable: true,
        classes: 'colpx-250 client-contact-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`${rowItem.primaryContactName}`;
        }
      },
      {
        title: '%%contact%% Email',
        fieldName: 'primaryContactEmail',
        classes: 'colpx-250 client-contact-email',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`${rowItem.primaryContactEmail}`;
        }
      },
      {
        title: 'Address',
        fieldName: 'physicalAddress',
        classes: 'colpx-400 client-address',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;
          const physicalAddress = rowItem.physicalAddress;

          if (physicalAddress)
            return html`${[physicalAddress.line1, physicalAddress.locality, physicalAddress.region, physicalAddress.postcode, physicalAddress.country]
              .filter(Boolean)
              .join(', ')}`;

          return ''
        }
      }
    ];
  }

  protected getPreFetched(_results: ResultBrowseClientSummary): Promise<void>[] {
    return [];
  }
}
