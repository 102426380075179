// eslint-disable-next-line import/named
import { html, LitElement, TemplateResult } from 'lit';
import { provide } from '@lit/context';
import { customElement, property, state } from 'lit/decorators.js';
import { showDevelopmentError } from '../development-error';

import './FormInputView';
import { urlForName } from './router';
import { isEmptyOrSpace } from './ui/helper-functions';
import {
  getCurrentUser,
  registerLangFile,
  registerTechnicalDictionary,
  run2FARegister,
  setCurrentUser,
  tlang
} from '@softtech/webmodule-components';
import { authenticationOptions } from '../api/api-injector';
import { isCynclyStaff, isSupplierAgent } from './currentuser-claims';
import { footerBrandLogoUrl } from './domain';
import { ImagePreview, imagePreviewContext } from './context/imagePreview';
import {
  localSettingsContext,
  sessionSettingsContext,
  userLocalSettings,
  userSessionSettings
} from './context/UserSettingsContext';
import { IUserSettings } from './context/IUserSettings';
import { appOutOfDate, getLocalBuildNumber } from './debug';
import { GlobalSupplierNotification } from '../v6config/supplier-services';

export interface MenuItem {
  name: string;
  title: string;
  path: string;
}

@customElement('app-index')
export class AppIndex extends LitElement {
  @property({ type: String }) appTitle?: string;
  @property({ type: String }) currentPath?: string;
  @property({ type: String }) menuItems?: string;
  @property({ type: String }) buildNumber?: string = getLocalBuildNumber();
  @property({ type: String }) userDisplayName?: string;

  @provide({ context: imagePreviewContext })
  imagePreview = new ImagePreview();

  @provide({ context: localSettingsContext })
  userSettings: IUserSettings = userLocalSettings;
  @provide({ context: sessionSettingsContext })
  userSessionSettings: IUserSettings = userSessionSettings;

  @state()
  protected logoTemplate: TemplateResult = html` <svg width="1" height="1"></svg>`;
  @state()
  outOfDate: boolean;
  constructor() {
    super();

    registerTechnicalDictionary(globalThis.dealerConfiguration.dictItems);
    registerLangFile(globalThis.dealerConfiguration.lang);
    this.addEventListener('webmodule-page-navigation', this.updateNavigation);
    this.addEventListener('wm-scroll-into-view', this.scrollElementIntoView);

    window.onunhandledrejection = event => {
      console.error(event.reason.stack);

      console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
      showDevelopmentError(event.reason);
    };
    globalThis.dealerConfiguration.litApp = this;
    window.onerror = function (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
      //Items to ignore
      if (source?.includes('apexcharts.js')) return;
      if (
        source?.includes('ResizeObserver') ||
        error?.message.includes('ResizeObserver') ||
        event.toString().includes('ResizeObserver')
      )
        return;

      console.warn(`UNHANDLED ERROR: ${error?.stack}`);
      if (error?.stack) console.error(error.stack);
      showDevelopmentError(
        error ??
          `
    ${event}
    source:${source}
    line:${lineno}
    col :${colno}
    `
      );
    };
    this.outOfDate = appOutOfDate();
  }

  @state()
  private _supplierOnline = GlobalSupplierNotification.getInstance().online;
  @state() private _supplierOfflineMessage = GlobalSupplierNotification.getInstance().offlineMessage;
  private _supplierStatusCallback = () => {
    this._supplierOnline = GlobalSupplierNotification.getInstance().online;
    this._supplierOfflineMessage = GlobalSupplierNotification.getInstance().offlineMessage;
  };
  connectedCallback(): void {
    super.connectedCallback();
    GlobalSupplierNotification.getInstance().addEventListenter(this._supplierStatusCallback);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    GlobalSupplierNotification.getInstance().remEventListenter(this._supplierStatusCallback);
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected getMenuItems(): Array<MenuItem> {
    if (this.menuItems) return JSON.parse(this.menuItems);
    return [];
  }

  protected getMenuItemTemplate(menuItem: MenuItem): TemplateResult {
    return html` <li class=${'nav-item ' + this.isPathActive(menuItem)}>
      <a class="nav-link" aria-current="page" href="${urlForName(menuItem.name)}">${menuItem.title}</a>
    </li>`;
  }

  private isPathActive(menuItem: MenuItem): string {
    const currentPath = this.currentPath;

    return currentPath?.toLowerCase() === menuItem.path.toLowerCase() ||
      (menuItem.path.toLowerCase() !== '/' && currentPath?.toLowerCase().startsWith(menuItem.path.toLowerCase()))
      ? ' active'
      : '';
  }

  render() {
    console.log();

    const logoutEvent = async (e: Event) => {
      e.preventDefault();
      await this.disconnectUser();
      await setCurrentUser(null);
    };

    const enable2FAEvent = (e: Event) => {
      e.preventDefault();
      run2FARegister(authenticationOptions());
    };
    const title2FA = getCurrentUser()?.Is2FAEnabled ? tlang`Register new 2FA App` : tlang`Enable 2FA`;
    const userMenu = !isEmptyOrSpace(this.userDisplayName)
      ? html` <span class="navbar-text">
          ${this.aboveUserNameTemplate()}
          <div class="dropdown current-user">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              id="current-user-menu-link"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >${this.userNameMenuContent()}
            </a>
            <ul class="dropdown-menu" aria-labelledby="current-user-menu-link">
              ${this.customUserMenuElements()}
              <li>
                <h1 class="dropdown-item">${tlang`%%user%%`}</h1>
              </li>
              <li>
                <a @click=${logoutEvent} class="dropdown-item" href="#">${tlang`Logout`}</a>
              </li>
              <li>
                <a @click=${enable2FAEvent} class="dropdown-item" href="#">${title2FA}</a>
              </li>
            </ul>
          </div>
        </span>`
      : html``;
    const supportClasses = isCynclyStaff()
      ? 'global-support-section cyncly-staff'
      : isSupplierAgent()
        ? 'global-support-section supplier'
        : '';
    const supplierOnlineTemplate = this._supplierOnline
      ? html``
      : html`<div class="supplier-offline-alert">
          <span>${this._supplierOfflineMessage}</span>
        </div>`;
    //this code is based on expecting only a single supplier instance, not multi instance
    const supportTemplate = isEmptyOrSpace(supportClasses)
      ? html``
      : html`<div class=${supportClasses}>${this.globalSupportInformation()}</div>`;
    return html`
      ${supplierOnlineTemplate} ${supportTemplate}
      <header>
        <nav class="navbar navbar-expand-lg navbar-header">
          <div class="container-fluid px-3">
            <a class="navbar-brand" href="/"> ${this.logoTemplate} </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="siteNavBar">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                ${this.getMenuItems().map(x => this.getMenuItemTemplate(x))}
              </ul>
              <wm-webmoduleautosaveindicator></wm-webmoduleautosaveindicator>
              <span class="me-1"></span>
              <icon-notification-signal></icon-notification-signal>
              ${userMenu}
            </div>
          </div>
        </nav>
      </header>

      <main id="main-page" role="main" class="flex-shrink-0 main-page">
        <div id="main-body"></div>
      </main>

      <footer class="footer mt-auto">
        <div class="d-flex flex-wrap justify-content-center align-content-center justify-content-lg-between">
          <div
            class="d-flex flex-wrap flex-lg-nowrap justify-content-center justify-content-md-start align-items-center footer-left-col"
          >
            <div class="st-logo pe-3">
              <img src="./assets/images/PoweredBySoftTech.svg" alt="Powered by SoftTech online" />
            </div>
            <div class="st-copyright pe-3">
              Copyright Soft Tech NZ LTD &copy; ${new Date().getFullYear()}, Powered by V6 Online
            </div>
            <div class="st-build px-3 border-start border-white">${this.generateBuildNumberText()}</div>
          </div>
          <div
            class="d-flex flex-wrap justify-content-center justify-content-md-end align-items-center footer-right-col"
          >
            <div class="supplier-footer-logo">${this.supplierFooterTemplate()}</div>
          </div>
        </div>
      </footer>
    `;
  }

  generateBuildNumberText(): TemplateResult {
    return html`${tlang`Build ${this.buildNumber}`}`;
  }
  supplierFooterTemplate(): TemplateResult {
    return html`<img src="${footerBrandLogoUrl()}" alt="Supplier Branded logo" />`;
  }
  globalSupportInformation(): unknown {
    return '';
  }
  userNameMenuContent(): unknown {
    return html` ${tlang`Welcome`} ${this.userDisplayName}`;
  }
  aboveUserNameTemplate(): unknown {
    return html``;
  }
  customUserMenuElements(): TemplateResult {
    return html``;
  }

  async disconnectUser() {
    //do nothing
    return true;
  }

  protected updateLogoTemplate() {
    this.logoTemplate = html`<svg width="1" height="1"></svg>`;
  }

  private updateNavigation(e) {
    this.currentPath = e.detail.path;
  }

  private scrollElementIntoView(
    e: CustomEvent<{
      element?: Element | undefined;
      id?: string | undefined;
      selector?: string | undefined;
    }>
  ) {
    let element: Element | undefined = e.detail.element;

    if (!element && e.detail.id) {
      element = this.querySelector(`#${e.detail.id}`) ?? undefined;
    }

    if (!element && e.detail.selector) {
      element = this.querySelector(e.detail.selector) ?? undefined;
    }

    if (element) element.scrollIntoView();
  }
}
