import {
  ResultBrowseClientSummary,
  ViewClientSummary,
  ViewContactSummary
} from '../../../api/dealer-api-interface-client';
import { emptyGuid } from '../../../api/guid';
import { DataCache } from '../../../cache/data-cache';
import { DataCacheGeneric } from '../../../cache/generic-data-cache';
import { EventContactOpen } from '../../../clients/data/events';
import { ClientSummaryTableBase, ClientSummaryTableBaseOptions } from '../../../clients/views/client-list-table';
import { EventSnippet } from '../../../components/ui/events';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { resolveURL } from '../../../components/ui/resource-resolver';
import { tlang, WebModuleLitTable, WebModuleLitTableColumnDef } from '@softtech/webmodule-components';
import { ClientCacheData } from '../../cache/cache-data';
import { cache } from '../../cache/cache-registry';
import { resourceClient } from '../ui/launcher';
import { customElement } from 'lit/decorators.js';
import { html } from 'lit';

interface ClientSummaryTableOptions extends ClientSummaryTableBaseOptions {
  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;
}

//TODO - Client Type should not be here... this is the generic layer, which has no idea of the meaning of a client type
//even though its passing in a generic datacache, it is still kind of incorrect. there should be a subclass of this in
//franchisee-dealer, or just move this there.
//this subclass should then override fetch from server, and make a second call to get the FranchiseeClients
//which would include the payment profile ids, which would then mean i dont need the hack to include a client
//cache in a client table.
@customElement('wm-clientsummarytable')
export class ClientSummaryTable extends ClientSummaryTableBase {
  paymentProfileCache: DataCacheGeneric = cache().paymentProfile;

  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  clientCache: DataCache<ClientCacheData> = cache().client;
  pageFragment: string;

  constructor(options: ClientSummaryTableOptions) {
    super(options);

    this.openContactEvent = options.openContactEvent;
    this.addButtonTitle = options.addButtonTitle;
    this.pageFragment = options.pageFragment;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%client%%`,
        fieldName: 'name',
        sortable: true,
        classes: 'colpx-250 client-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`<a class="client-link" data-client-id=${rowItem.name}
                         href="${resolveURL(resourceClient, rowItem.id)}">${rowItem.name}</a>`;
        }
      },
      {
        title: tlang`Primary %%contact%%`,
        fieldName: 'primaryContactName',
        sortable: true,
        classes: 'colpx-250 client-contact-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          if (rowItem.primaryContactName)
            return html`<a class="contact-link" data-contact-id=${rowItem.primaryContactId}
                           href="#">${rowItem.primaryContactName}</a>`;

          return '';
        },
        click: (e: Event, _table: WebModuleLitTable, item: unknown) => {
          e.preventDefault();
          e.stopImmediatePropagation();

          const rowItem = item as ViewClientSummary;

          this.eventHandler(async () => {
            await this.openContact({
              id: rowItem.primaryContactId ?? emptyGuid,
              name: rowItem.primaryContactName ?? '',
              clientId: rowItem.id,
              clientName: rowItem.name,
              title: '',
              email: '',
              isPrimary: false,
              mobile: null
            });
          })

          return true;
        }
      },
      {
        title: tlang`%%contact%% Email`,
        fieldName: 'primaryContactEmail',
        classes: 'colpx-250 client-contact-email',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`${rowItem.primaryContactEmail}`;
        }
      },
      {
        title: tlang`Address`,
        fieldName: 'physicalAddress',
        classes: 'colpx-400 client-address',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;
          const physicalAddress = rowItem.physicalAddress;

          if (physicalAddress)
            return html`${[physicalAddress.line1, physicalAddress.locality, physicalAddress.region, physicalAddress.postcode, physicalAddress.country]
              .filter(Boolean)
              .join(', ')}`;

          return ''
        }
      },
      {
        title: tlang`%%payment-profile%%`,
        fieldName: 'id',
        classes: 'colpxmax-240 client-client-type',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewClientSummary;

          return html`${this.getClientDefaultPaymentProfile(rowItem.id)}`;
        }
      }
    ];
  }

  async doPreFetching(results: ResultBrowseClientSummary) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const clientKeys = results.clientSummary.results!.map(x => x.id ?? '');
    //pump all the clients into a local cache if not there already.
    await this.clientCache.preFetch(clientKeys);
    await super.doPreFetching(results);
  }

  protected getPreFetched(results: ResultBrowseClientSummary): Promise<void>[] {
    const preFetchedList = super.getPreFetched(results);
    const profileKeys = results.clientSummary.results?.map(x => {
      const cacheItem = this.clientCache.getLocal(x.id)?.data;
      const fclient = cacheItem?.franchiseeClient;
      return fclient?.paymentProfileId ?? emptyGuid;
    });
    preFetchedList.push(this.paymentProfileCache.preFetch(profileKeys));
    return preFetchedList;
  }

  private async openContact(viewContactSummary: ViewContactSummary): Promise<void> {
    await this.openContactEvent?.(viewContactSummary);
  }

  private getClientDefaultPaymentProfile(value: string) {
    if (isEmptyOrSpace(value)) return '';
    //prefetch should already have been called
    const paymentProfileId = this.clientCache.getLocalData(value)?.franchiseeClient?.paymentProfileId;

    return this.paymentProfileCache.getLocal(paymentProfileId)?.displayValue ?? '';
  }
}
