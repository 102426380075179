import { TemplateResult } from 'lit-html';
import { constructAsync } from '../../../async-constructor';
import { PaymentProfileContainerManager } from '../../../franchisee/data/payment-profile-container-manager';
import { PaymentProfileDetailView } from '../../../franchisee/view/payment-profile-detail-view';
import { PaymentProfileDataEntryView, PaymentProfileView } from '../../../franchisee/view/payment-profile-view';
import { cache } from '../../cache/cache-registry';
import { FranchiseePaymentProfileDetailView } from './franchisee-payment-profile-detail-view';
import { buttonsSaveCancel } from '../../../components/ui/modal-confirmation';
import { customElement } from 'lit/decorators.js';

@customElement('wm-franchiseepaymentprofiledataentryview')
export class FranchiseePaymentProfileDataEntryView extends PaymentProfileDataEntryView {
  protected paymentProfileContainerManagerFactory(): PaymentProfileContainerManager {
    const manager = new PaymentProfileContainerManager(this.paymentProfile, this.eventTitle, this.paymentProfileApi);
    manager.afterSave.push(() => {
      cache().paymentProfile.flush([this.paymentProfile.paymentProfileId]);
    });
    return manager;
  }

  protected async paymentProfileDetailViewFactory(): Promise<PaymentProfileDetailView> {
    return await constructAsync(
      new FranchiseePaymentProfileDetailView(this, {
        paymentProfileManager: this.paymentProfileManager
      })
    );
  }
}

@customElement('wm-franchiseepaymentprofileview')
export class FranchiseePaymentProfileView extends PaymentProfileView {
  protected createView(): PaymentProfileDataEntryView {
    return new FranchiseePaymentProfileDataEntryView(this.options);
  }

  protected async save() {
    return this.view?.performAutoSave();
  }

  protected footerTemplate(): TemplateResult | null {
    const saveEvent = async () => {
      if (await this.save()) await this.hideModal();
    };
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, saveEvent, buttons.cancel);
  }
}
