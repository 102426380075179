// eslint-disable-next-line import/named
import { html } from 'lit';
import { newGuid } from '../../api/guid';
import { EventGetReference, EventSnippet, EventTemplate } from '../../components/ui/events';
import { IconRefresh } from '../../components/ui/icons/icon-refresh';
import { PageControl, PageControlOptions, PageManager } from '../../components/ui/page-control';
import { PurchaseOrderSummaryTable, PurchaseOrderSummaryTableOptions } from './purchase-order-summary-table';
import { constructAsync } from '../../async-constructor';
import { PurchaseOrderState, ViewPurchaseOrderTotalByState } from '../../api/dealer-api-interface-franchisee';
import { tlang } from '@softtech/webmodule-components';
import { PurchaseOrderContainer } from '../data/purchase-order-container';
import { PurchaseOrderView, PurchaseOrderViewOptions } from './purchase-order-view';
import { ViewBase } from '../../components/ui/view-base';
import { moneyToTemplateResult } from '../../components/currency-formatter';
import { getApiFactory } from '../../api/api-injector';
import { PurchaseOrderApi } from '../../api/purchase-order-api';
import { customElement, state } from 'lit/decorators.js';
const numberOfDaysHistory = 30;
export interface PurchaseOrderListViewOptions {
  purchaseOrderOwnerId: EventGetReference;
}
@customElement('wm-purchaseorderlistview')
export class PurchaseOrderListView extends ViewBase {
  protected readonly purchaseOrderOwnerId: EventGetReference;
  protected pageControl: PageControl;
  private readonly elementId: string;
  protected tables: PurchaseOrderSummaryTable[];
  @state()
  protected purchaseOrderTotals: ViewPurchaseOrderTotalByState[] | null = null;
  private purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();
  protected staleOrders: string[] = [];
  constructor(options: PurchaseOrderListViewOptions) {
    super();
    this.elementId = `purchase-order-list-view-${newGuid()}`;

    this.purchaseOrderOwnerId = options.purchaseOrderOwnerId;

    this.tables = this.initializeTables();
    this.pageControl = this.createPageControl();
  }
  public async setActiveTabByHash() {
    await this.pageControl?.applyWindowHash();
  }
  public async afterConstruction(): Promise<void> {
    await this.updatePageTotals();

    const table = this.pageControl.activePage?.data as PurchaseOrderSummaryTable;

    if (table) await table.refreshData(1);
  }

  public async doAfterPurchaseOrderEdit() {
    await this.updatePageTotals();
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      return this.tables.map(table => {
        const pm: PageManager = {
          caption: table.eventTitle,
          canClose: () => Promise.resolve(false),
          canLeave: () => Promise.resolve(true),
          hasDelete: () => false,
          onEnter: async () => {
            //enable data load the first time we enter the tab, but not before
            table.delayedDataLoad = false;
            // not-awaiting on purpose, for faster page switch. safe to do
            if (table.dataIsStale) table.refreshData();
          },
          content: () => {
            return table;
          },
          data: table,
          pageFragment: table.pageFragment
        };
        return pm;
      });
    };
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: [
        {
          event: async () => {
            if (this.pageControl.activeTabIndex < this.tables.length) {
              const table = this.pageControl.activePage?.data as PurchaseOrderSummaryTable;
              this.beforeRefresh();
              await table.refreshData();
              this.tables.forEach(t => {
                if (t !== table) {
                  t.dataIsStale = true;
                }
              });
              this.afterRefresh();
              return true;
            } else return false;
          },
          caption: () => html`${new IconRefresh()}`
        }
      ],
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }
  afterRefresh() {
    //nothing
  }
  beforeRefresh() {
    this.staleOrders = []; //nothing
  }

  protected async updatePageTotals() {
    const ownerId = await this.purchaseOrderOwnerId();

    const totals = await this.purchaseOrderApi.getTotalsByState({
      ownerId: ownerId,
      options: [
        {
          state: PurchaseOrderState.Draft | PurchaseOrderState.Issued | PurchaseOrderState.IssuedPending,
          numberOfDaysHistory: null
        },
        { state: PurchaseOrderState.Completed | PurchaseOrderState.Cancelled, numberOfDaysHistory: numberOfDaysHistory }
      ]
    });

    if (totals) this.purchaseOrderTotals = totals.totals.filter(x => x.ownerId === ownerId);

    this.pageControl.requestUpdate();
  }

  protected async purchaseOrderViewFactory(options: PurchaseOrderViewOptions): Promise<PurchaseOrderView> {
    return await constructAsync(new PurchaseOrderView(options));
  }

  //this can be overridden in sub class to replace the table with different column/view etc
  protected purchaseOrderSummaryTableFactory(options: PurchaseOrderSummaryTableOptions): PurchaseOrderSummaryTable {
    return new PurchaseOrderSummaryTable(options);
  }

  protected createSummaryTable(
    title: EventSnippet,
    purchaseOrderState: PurchaseOrderState,
    pageFragment: string,
    numberOfDays?: number
  ): PurchaseOrderSummaryTable {
    return this.purchaseOrderSummaryTableFactory({
      purchaseOrderState: purchaseOrderState,
      numberOfDaysHistory: numberOfDays,
      title: title,
      purchaseOrderOwnerId: this.purchaseOrderOwnerId,
      pageFragment: pageFragment,
      stale: { exists: (id: string) => this.staleOrders.includes(id) }
    });
  }

  protected createPurchaseOrderContainer(purchaseOrderId: string): PurchaseOrderContainer {
    return new PurchaseOrderContainer(purchaseOrderId, null, null);
  }

  //override this in a subclass to change the design, and order of the tables
  protected initializeTables(): PurchaseOrderSummaryTable[] {
    const title = (caption: string, state: PurchaseOrderState, hideTotal = false) => {
      let count = 0,
        total = 0;

      this.purchaseOrderTotals
        ?.filter(x => (state & x.state) === x.state)
        .forEach(x => {
          total += x.total;
          count += x.count;
        });

      return html`${caption} (${count})${hideTotal ? html`` : moneyToTemplateResult(total)}`;
    };
    const makeTable = (
      titleStr: string,
      state: PurchaseOrderState,
      pageFragment: string,
      numberOfDays?: number,
      hideTotal?: boolean
    ) => {
      return this.createSummaryTable(() => title(titleStr, state, hideTotal), state, pageFragment, numberOfDays);
    };

    return [
      makeTable(
        tlang`All Current`,
        PurchaseOrderState.Draft | PurchaseOrderState.Issued | PurchaseOrderState.IssuedPending,
        'allCurrent',
        undefined,
        true
      ),
      makeTable(tlang`Draft`, PurchaseOrderState.Draft, 'draft', undefined, true),
      makeTable(tlang`Issued`, PurchaseOrderState.Issued | PurchaseOrderState.IssuedPending, 'issued', undefined, true),
      makeTable(tlang`Completed`, PurchaseOrderState.Completed, 'completed', numberOfDaysHistory, true),
      makeTable(tlang`Cancelled`, PurchaseOrderState.Cancelled, 'cancelled', numberOfDaysHistory, true)
    ];
  }

  protected async updateAndRefreshPurchaseOrderTab(switchToActiveTab?: boolean) {
    if (switchToActiveTab)
      if (this.pageControl.activeTabIndex !== 0) {
        this.tables[0].refreshData();
        await this.pageControl.setActiveTabIndex(0);
      } else await this.tables[0].refreshData();
    else await this.tables[this.pageControl.activeTabIndex].refreshData();
  }

  protected template(): EventTemplate {
    return html` <div id=${this.elementId} class="page-content">${this.pageControl?.ui}</div>`;
  }
}
