// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { toJsonStr } from '../../blob/converters';
import { QuoteItemView, QuoteItemViewOptions } from '../views/quote-item-view';
import { EventTemplate, Snippet } from '../../components/ui/events';
import { DataBinding, getInternalId } from '../../components/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { tlang, userSecurity } from '@softtech/webmodule-components';
import { emptyGuid, newGuid } from '../../api/guid';
import { quoteSupplierProvider } from '../data/quoteSupplierProvider';
import { DevelopmentError, showDevelopmentError } from '../../development-error';
import { information } from '../../components/ui/modal-option';
import { supplierQuoteItemContentType } from '../data/supplier-quote-item-content-type';
import { v6BootLoaderWithFailMsg, v6Editors } from '../../v6config/v6config';
import {
  applyV6PricesToQuoteItemPrice,
  getQuoteFrameVersion,
  getV6QuoteItemForContainer,
  isSupplierUsingSSI
} from '../data/v6/helper-functions';
import {
  QuoteFrameDataProvider,
  v6FrameUserMode,
  V6PickerFamilyButton,
  V6QuoteFrameEditor,
  V6QuoteFrameEditorData
} from '@softtech/webmodule-data-contracts';
import { StockLookupView } from '../../api/dealer-api-interface-franchisee';
import { InputUpdateQuoteItems, QuoteItemPrice, QuoteItemType } from '../../api/dealer-api-interface-quote';

import { offline } from '../../components/ui/offline';
import { getQuoteSuppliers } from '../quote-service';
import { getQuoteItemTab } from '../data/quote-helper-functions';
import { firstValidString } from '../../components/ui/helper-functions';
import { compare } from '../../components/clone';
import { customElement } from 'lit/decorators.js';
import { QuoteItemContainer } from '../data/quote-item-container';
import { nowServer } from '../../components/datetime-converter';

enum V6QuoteItemSaveState {
  nothing = 0,
  prepareForSave = 10,
  communicateWithSupplierSystem = 20,
  getBuyInCosts = 25,
  updatePricing = 28,
  CollectQuoteContract = 40,
  RequestSSI = 50,
  UpdateLocalSSI = 60,
  UpdateAllQuoteItems = 70,
  UpdateComplete = 80
}
@customElement('wm-quoteitemframeviewforv6')
export class QuoteItemFrameViewForV6 extends QuoteItemView implements QuoteFrameDataProvider {
  v6Editor: V6QuoteFrameEditor | null = null;
  dataTracker: DataTracker;
  framePicker?: V6PickerFamilyButton;
  constructor(options: QuoteItemViewOptions) {
    super(options);

    this.dataTracker = new DataTracker(new DataBinding(this.ui, getInternalId()));
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.quoteItemContainer?.item),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('title');
    addField('description');
    addField('comment');

    // Changed nullable to true since we are checking the field in the validation.
    addField('quantity', FieldType.int, true);
  }

  //only used for new frames
  private _id: string = newGuid();

  get id(): string {
    return this.quoteItemContainer?.item.id ?? this._id;
  }

  get supplierReferenceOverrideKey(): string {
    //the quote owner is the branch ID, which is what we are tying our v6 customer to
    return this.quoteManager.quote.quoteOwnerId;
  }
  private supplierOffline = false;
  get readonly(): boolean {
    return this.isDataReadonly() || this.supplierOffline;
  }

  get quoteItem() {
    if (!this.quoteItemContainer) return undefined;
    return getV6QuoteItemForContainer(this.quoteItemContainer);
  }

  get quoteItemVersion(): string | undefined {
    if (!this.quoteItemContainer) return undefined;
    return getQuoteFrameVersion(this.quoteItemContainer);
  }

  get quoteIGUs() {
    return this.getQuoteIGUs();
  }

  get userMode(): string | undefined {
    if (userSecurity().isPowerUser()) return v6FrameUserMode.powerUser;
    else return v6FrameUserMode.standardUser;
  }

  get quantity(): number {
    if (!this.quoteItemContainer) return 1;
    try {
      const uiValue = Math.max(1, this.getQuantityFromUI());
      return uiValue;
    } catch {
      return this.quoteItemContainer?.item.quantity ?? 1;
    }
  }

  get thumbnailURL() {
    return this.quoteItemContainer
      ? this.quoteApi.api.fullUrl(`api/file/${this.quoteItemContainer?.item.virtualThumbnailPath}`)
      : '';
  }

  get quoteDefaults() {
    return this.getQuoteDefaults();
  }

  //QuoteFrameDataProvider
  async dispose(): Promise<void> {
    await super.dispose();
    if (!this.readonly) this.v6Editor?.releaseResources();
    this.v6Editor = null;
  }

  /**
   * inherited
   * @returns
   */
  public isTab(): boolean {
    return true;
  }

  /**
   * inherited
   * @returns
   */
  public async prepareForSave(): Promise<void> {
    await this.setQuoteItemSaveState(V6QuoteItemSaveState.prepareForSave);
    if (this.readonly) return;
    if (!this.v6Editor) return;
    if (this.quoteItemContainer) {
      if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();

      //the provider data is a base64 encoding of the v6configuration data
      //normally we only want to assign this data when we hit the save button
      //for now, we are updating the quoteitem every single call back so that
      //it stress tests the system
      //TODO-we dont need to adjust this every single time normally, only when
      if (!this.quoteItemContainer.data)
        throw new DevelopmentError('v6-quote-item-frame-view, quoteItemContainer.data is null');

      //design changes now mean that we always need to trigger the server call to update buyin items, pricing, and server side macro
      //events. we can't assume nothing has changed.
      await this.setQuoteItemSaveState(V6QuoteItemSaveState.communicateWithSupplierSystem);
      let details: V6QuoteFrameEditorData | null = null;
      try {
        const attr = (this.v6Editor as any).modifiedAttributes;
        if (attr.length > 0 || this.quoteManager.changedItemQuantity(this.quoteItemContainer?.item.id))
          details = await this.v6Editor.commit();
      } catch {
        await offline();
      }
      if (details) {
        const buyIn = details?.buyInItems;

        if (buyIn && buyIn.length > 0) await this.setQuoteItemSaveState(V6QuoteItemSaveState.getBuyInCosts);
        const buyInCosts = await this.quoteManager.getBuyInCosts(buyIn);
        const buyInMetaData = this.buyInDataJsonStr(buyInCosts);

        this.quoteItemContainer.item.isRestrictedToPowerUser = details.requiredUserMode == v6FrameUserMode.powerUser;
        await this.setQuoteItemSaveState(V6QuoteItemSaveState.updatePricing);

        const originalItemPrice = this.quoteManager.clone(this.quoteItemContainer.price);
        applyV6PricesToQuoteItemPrice(
          this.quoteItemContainer.item.quantity,
          details.price,
          this.quoteItemContainer.price,
          buyInCosts
        );
        //do this last. the pricing if it occured will update our internal quote item object.
        this.quoteItemContainer.data.providerData = toJsonStr(details.quoteItem) ?? null;

        this.quoteItemContainer.buyInData = buyInMetaData ?? undefined;

        // if the pricing from V6 is different from what we originally had, clear any supplier price adjustment.
        if (!compare(originalItemPrice, this.quoteItemContainer.price))
          this.quoteItemContainer.price.supplierPriceAdjustment = 0;
      }
      return;
    }
    return;
  }

  public resetEditControls() {
    if (this.dataTracker.modified) {
      this.dataTracker.resetEditorValue();
    }
  }

  public async prepareEditor(): Promise<void> {
    try {
      if (await v6BootLoaderWithFailMsg()) {
        if (!this.readonly) {
          const suppliers = await getQuoteSuppliers(true);
          this.supplierOffline = !(
            suppliers.find(x => x.supplierId === this.quoteManager.quote.supplierId)?.online ?? false
          );
          if (this.supplierOffline)
            await information(
              tlang`%%frame%% is opening readonly because the %%supplier%% is currently offline. please try again soon`
            );
        }
        this.v6Editor = await v6Editors().getQuoteFrameEditorService(this);
        if (this.v6Editor) {
          this.v6Editor.ui.addEventListener('v6frameview', e => {
            const ev: CustomEvent = e as CustomEvent;
            if (ev.detail.error && ev.detail.shutdownRequired) this.abandonAndClose(true);
          });
        }
      }
      this._readyToEdit = this.v6Editor != null;
      if (this.v6Editor && !this.quoteItemContainer) {
        this._readyToEdit = false;
        let details: V6QuoteFrameEditorData | null = null;
        try {
          details = await this.v6Editor.commit();
        } catch {
          await offline();
          return;
        }
        if (details) {
          const price = details.price;
          const providerType = quoteSupplierProvider.v6;
          const providerReferenceId: string = emptyGuid;
          const providerData = toJsonStr(details.quoteItem);

          const buyIn = details.buyInItems;
          const buyInCosts = await this.quoteManager.getBuyInCosts(buyIn);
          const { quoteItemCommonId, ...priceSourceData } = price;
          const id = this._id;
          const date = nowServer();

          //create mock price object so we can use the same
          //code for generating the price
          const qprice: QuoteItemPrice = {
            calculatedGrossSellingPrice: 0,
            calculatedNetSellingPrice: 0,
            dateCreated: date,
            id: id,
            marginPercentage: 0,
            priceAdjustment: 0,
            quantityCost: 0,
            recordVersion: '',
            requiresRepricing: false,
            singleUnitCost: 0,
            sourceData: priceSourceData,
            supplierGrossQuantityCost: 0,
            supplierGrossSingleUnitCost: 0,
            supplierNettQuantityCost: 0,
            supplierNettSingleUnitCost: 0,
            supplierPriceAdjustment: 0,
            isTaxableItem: true
          };
          applyV6PricesToQuoteItemPrice(1, details.price, qprice, buyInCosts);

          const buyInMetaData = this.buyInDataJsonStr(buyInCosts);
          const exInput: QuoteItemContainer = {
            data: {
              id: id,
              dateCreated: date,
              lastModifiedDate: date,
              lastModifiedUserId: emptyGuid,
              providerData: providerData ?? null,
              recordVersion: ''
            },
            buyInData: buyInMetaData ?? undefined,
            item: {
              comment: '',
              commonId: id,
              dateCreated: date,
              description: this.v6Editor.frameDescription,
              id: id,
              isRestrictedToPowerUser: details.requiredUserMode === v6FrameUserMode.powerUser,
              itemType: QuoteItemType.Provider,
              lastModifiedDate: date,
              lastModifiedUserId: emptyGuid,
              providerReferenceId: providerReferenceId,
              serviceProvider: providerType,
              quantity: 1,
              quoteId: this.quoteManager.quoteId,
              quoteItemContentType: supplierQuoteItemContentType.CID_FRAM,
              recordVersion: '',
              title: tlang`%%frame-title%%`,
              virtualThumbnailPath: ''
            },
            price: qprice
          };

          const ssiProcessor = this.quoteManager.ssiProcessor(undefined, [exInput]);
          const ssiInputs = await ssiProcessor.processSSI();

          this.quoteItemContainer = await this.quoteManager.createQuoteItemEx(
            exInput,
            this.v6Editor.thumbnail,
            ssiInputs
          );
        }
        this._readyToEdit = this.quoteItemContainer != null;
      }
    } catch (e) {
      await showDevelopmentError(e as Error);
      this._readyToEdit = false;
    }
  }
  // }
  public getImg(): string {
    if (this.v6Editor) {
      return this.v6Editor.thumbnail;
    }
    return '';
  }

  propertiesTemplate(): TemplateResult {
    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());

    if (this.quoteItemContainer?.item) {
      return html` <div class="form-one-col quoteItemFrameProperties">
        <div class="row attributeContents">
          <div class="v6config-group">
            <div class="v6config-group-header">${tlang`Properties`}</div>
            ${forms.text('title', tlang`%%frame-title%%`, 120)} ${forms.text('description', tlang`Description`, 120)}
            ${forms.int('quantity')}
            ${forms.note(
              'comment',
              tlang`Notes`,
              250,
              tlang`This detail is published on %%quote%% Reports and submitted to the Manufacturer with the %%purchase-order%%`,
              tlang`250 Character Limit`
            )}
          </div>
        </div>
      </div>`;
    } else return html``;
  }

  public internalDataChanged(): boolean {
    return !this.readonly && this.quoteManager.changedItem(this.quoteItemContainer?.item.id);
  }

  displaySaveModal(): boolean {
    return true;
  }

  public allowDeletePage(): boolean {
    return true;
  }

  discardLabel(): unknown {
    return tlang`Discard %%frame%% Changes`;
  }

  buttonMenu(): Snippet {
    return html`${this.abandonAndCloseButton()}`;
  }

  getDataDictionaryName(): string {
    return '%%frame%%';
  }

  public isDataReadonly(): boolean {
    return this.quoteManager.isReadonly();
  }

  protected async revertChanges(): Promise<boolean> {
    if (this.quoteItemContainer) {
      const backup = this.quoteManager.quoteItemContainer(this.quoteItemContainer.item.id, true);
      this.quoteItemContainer.item.title = backup.item.title;
      this.quoteItemContainer.item.description = backup.item.description;
      this.quoteItemContainer.item.quantity = backup.item.quantity;
      this.quoteItemContainer.item.comment = backup.item.comment;
    }
    return true;
  }

  protected getValidationErrors(): string[] {
    const errors: string[] = [];
    const quantity = this.getQuantityFromUI();
    if (isNaN(quantity) || quantity < 1) errors.push(tlang`Quantity of ${quantity} is invalid`);

    return errors;
  }

  protected async internalSaveData(): Promise<boolean> {
    //TODO- At this point, we have done everything needed to save the frame item. now we need to update the entire quote.
    let updateItems: InputUpdateQuoteItems | undefined = undefined;
    if (isSupplierUsingSSI(this.quoteManager.quote.supplierId)) {
      await this.setQuoteItemSaveState(V6QuoteItemSaveState.RequestSSI);
      const ssi = this.quoteManager.ssiProcessor();
      updateItems = await ssi.processSSI();

      await this.setQuoteItemSaveState(V6QuoteItemSaveState.UpdateLocalSSI);
    }

    await this.setQuoteItemSaveState(V6QuoteItemSaveState.UpdateAllQuoteItems);
    try {
      if (this.quoteItemContainer) {
        this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItem(
          this.quoteItemContainer,
          this.getImg(),
          updateItems
        );
        return this.quoteManager.lastSaveSuccessful;
      }

      return false;
    } finally {
      await this.setQuoteItemSaveState(V6QuoteItemSaveState.UpdateComplete);
    }
  }

  protected getCaption(): Snippet {
    return getQuoteItemTab(
      firstValidString(this.v6Editor?.frameDescription, tlang`New %%frame%%`),
      this.quoteManager.itemPosition(this.quoteItemContainer?.item.id)
    );
  }

  protected bodyTemplate(): EventTemplate {
    if (this.v6Editor) {
      return html`${this.v6Editor.ui}`;
    } else return html``;
  }

  protected getQuoteIGUs(): unknown {
    throw new DevelopmentError('getQuoteIGUs() must override');
  }

  protected getQuoteDefaults(): unknown {
    throw new DevelopmentError('getQuoteDefaults() must override');
  }

  protected async readyToClose(): Promise<boolean> {
    if (this.v6Editor?.busy) {
      await information(tlang`please wait until processing is complete and try again`);
      return false;
    }
    return true;
  }

  private async setQuoteItemSaveState(state: V6QuoteItemSaveState) {
    let info = '';
    const qic = this.quoteItemContainer;
    const qi = qic?.item;
    if (!qi) {
      await this.informationDispatcher.setInformation('');
      return;
    }
    switch (state) {
      case V6QuoteItemSaveState.nothing:
        info = tlang`# Editing %%frame%%

## ${qi.title}  `;
        break;
      case V6QuoteItemSaveState.prepareForSave:
        info = tlang`# Preparing to save "${qi.title}"  `;
        break;
      case V6QuoteItemSaveState.communicateWithSupplierSystem:
        info = tlang`# Preparing to save "${qi.title}"  

+ Getting %%frame%% updates from %%supplier%%
                `;
        break;
      case V6QuoteItemSaveState.CollectQuoteContract:
        info = tlang`# Preparing to save "${qi.title}"  

+ Prepare information for %%supplier%% processing
                `;
        break;
      case V6QuoteItemSaveState.updatePricing:
        info = tlang`# Preparing to save "${qi.title}"  

+ updating %%quote%% price
                `;
        break;
      case V6QuoteItemSaveState.RequestSSI:
        info = tlang`# Preparing to save "${qi.title}"  

+ requesting updates from %%supplier%%
                `;
        break;
      case V6QuoteItemSaveState.UpdateLocalSSI:
        info = tlang`# Preparing to save "${qi.title}"  

+ Updating %%quote%% with %%supplier%% changes
                `;
        break;
      case V6QuoteItemSaveState.UpdateAllQuoteItems:
        info = tlang`# Saving "${qi.title}"  

+ Sending updates
                `;
        break;
      case V6QuoteItemSaveState.UpdateComplete:
        info = tlang`# Saving "${qi.title}"  

+ Completed
                `;
        break;
    }
    await this.informationDispatcher.setInformation(info);
  }

  private getQuantityFromUI(): number {
    return this.dataTracker.getEditorValue('quantity') as number;
  }

  private buyInDataJsonStr(buyInCosts?: StockLookupView[]): string | undefined {
    if (buyInCosts && buyInCosts.length > 0) {
      return toJsonStr(buyInCosts) ?? undefined;
    } else return undefined;
  }
}
