import { PaymentProfileApi } from '../../api/payment-profile-api';
import { RequestPage, ResultPaginated } from '../../components/ui/datatable-view';
import { EventSnippet } from '../../components/ui/events';
import { EventPaymentProfileOpen } from '../data/events';
import { PaymentProfileSummary, ResultBrowsePaymentProfileSummary } from '../../api/dealer-api-interface-franchisee';
import { tlang, WebModuleLitTable, WebModuleLitTableColumnDef } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { isMultiSupplier } from '../../quotes/quote-service';
import { customElement } from 'lit/decorators.js';
import { LitTableWrapper } from '../../components/ui/littable-view';
import { html } from 'lit';
import { getQuoteSupplierDisplayName } from '../../quotes/data/quoteSupplierProvider';

//This base class can be refactored out and more generic
interface PaymentProfileSummaryTableBaseOptions {
  title: EventSnippet;
  supplierId: string | null;
}

@customElement('wm-paymentprofilesummarytablebase')
class PaymentProfileSummaryTableBase extends LitTableWrapper<PaymentProfileSummary> {
  eventTitle: EventSnippet;
  api: PaymentProfileApi = getApiFactory().paymentProfile();
  filter: string | null;
  supplierId: string | null = null;

  constructor(options: PaymentProfileSummaryTableBaseOptions) {
    super();
    this.eventTitle = options.title;
    this.filter = null;
    this.supplierId = options.supplierId;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PaymentProfileSummary>> {
    const results = await this.api.browsePaymentProfileSummary({
      branchId: userDataStore.defaultBranch.id,
      sortField: request.sortField,
      sortAsc: !request.sortAsc,
      filter: this.filter,
      requestPage: {
        pageSize: request.pageSize,
        pageIndex: request.pageIndex
      },
      supplierId: this.supplierId
    });
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    await Promise.all(this.getPreFetched(results));

    return results.paymentProfileSummary;
  }

  enableFiltering(): boolean {
    return true;
  }

  updateFilter(_searchTerm: string | null) {
    this.filter = _searchTerm;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    const columns: WebModuleLitTableColumnDef[] = [
      {
        title: tlang`%%payment-profile%%`,
        fieldName: 'id',
        classes: 'colpx-500 payment-profile-name',
        sortable: true,
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PaymentProfileSummary;

          return html`<a class="payment-profile-link" data-paymen-profile-id=${rowItem.id}
                         href="#">${rowItem.name}</a>`;
        }
      },
      {
        title: tlang`Margin`,
        fieldName: 'margin',
        classes: 'colpx-500 payment-profile-margin',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PaymentProfileSummary;
          return html`${rowItem.margin}`;
        }
      }
    ];

    if (isMultiSupplier())
      columns.push({
        title: tlang`%%supplier%%`,
        fieldName: 'supplierId',
        classes: 'colmax-500 payment-profile-supplier',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PaymentProfileSummary;
          return html`${getQuoteSupplierDisplayName(rowItem.supplierId)}`;
        }
      });

    return columns;
  }

  protected getPreFetched(_results: ResultBrowsePaymentProfileSummary): Promise<void>[] {
    return [];
  }
}

interface PaymentProfileSummaryTableOptions extends PaymentProfileSummaryTableBaseOptions {
  openPaymentProfileEvent: EventPaymentProfileOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;
}

@customElement('wm-paymentprofilesummarytable')
export class PaymentProfileSummaryTable extends PaymentProfileSummaryTableBase {
  openPaymentProfileEvent: EventPaymentProfileOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;

  constructor(options: PaymentProfileSummaryTableOptions) {
    super(options);
    this.openPaymentProfileEvent = options.openPaymentProfileEvent;
    this.addButtonTitle = options.addButtonTitle;
    this.pageFragment = options.pageFragment;
  }

  pageLength(): number {
    return 20;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%payment-profile%%`,
        fieldName: 'id',
        classes: 'colpx-500 payment-profile-name',
        sortable: true,
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PaymentProfileSummary;

          return html`<a class="payment-profile-link" data-paymen-profile-id=${rowItem.id}
                         href="#">${rowItem.name}</a>`;
        },
        click: (e: Event, _table: WebModuleLitTable, item: unknown) => {
          e.preventDefault();
          e.stopImmediatePropagation();

          const rowItem = item as PaymentProfileSummary;

          this.eventHandler(async () => {
            await this.openPaymentProfile(rowItem);
          })

          return true;
        }
      },
      {
        title: tlang`Margin`,
        fieldName: 'margin',
        classes: 'colpx-500 payment-profile-margin',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PaymentProfileSummary;
          return html`${rowItem.margin}`;
        }
      }
    ];
  }

  protected async openPaymentProfile(paymentProfileSummary: PaymentProfileSummary): Promise<void> {
    await this.openPaymentProfileEvent?.(paymentProfileSummary);
  }
}

export interface PaymentProfileSummaryPickerOptions extends PaymentProfileSummaryTableBaseOptions {
  onPaymentProfileSelected?: (paymentProfileSummary: PaymentProfileSummary) => void;
}

@customElement('wm-paymentprofilesummarypickerbrowser')
export class PaymentProfileSummaryPickerBrowser extends PaymentProfileSummaryTableBase {
  onPaymentProfileSelected?: (paymentProfileSummary: PaymentProfileSummary) => void;

  constructor(options: PaymentProfileSummaryPickerOptions) {
    super(options);
    this.onPaymentProfileSelected = options.onPaymentProfileSelected;
    this.clickrows = true;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%payment-profile%%`,
        fieldName: 'id',
        classes: 'colpx-500 payment-profile-name',
        sortable: true,
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PaymentProfileSummary;

          return html`<span class="payment-profile-link" data-paymen-profile-id=${rowItem.id}>${rowItem.name}</span>`;
        },
      },
      {
        title: tlang`Margin`,
        fieldName: 'margin',
        classes: 'colpx-500 payment-profile-margin',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PaymentProfileSummary;
          return html`${rowItem.margin}`;
        }
      }
    ];
  }

  async rowClicked(e: CustomEvent<{ table: WebModuleLitTable; item: PaymentProfileSummary }>): Promise<void> {
    e.stopImmediatePropagation();
    e.preventDefault();

    this.eventHandler(async () => {
      if (this.onPaymentProfileSelected) this.onPaymentProfileSelected?.(e.detail.item);
    });
  }
}
