import { html } from 'lit';

import { property, state } from 'lit/decorators.js';
import { PageBase } from './helpers/page-base';
import { createStagedEvent, defaultStagedThreshold, EventCancellation, tlang } from '@softtech/webmodule-components';
import { getInternalId } from '../../components/ui/databinding/databinding';
import { isEmptyOrSpace, validId } from '../../components/ui/helper-functions';
import { NullPromise } from '../../null-promise';
import { showDevelopmentError } from '../../development-error';
import { DataEntryViewController, ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { DataEntryOwner } from '../../components/ui/DataEntryOwner';
import { goStaticURL } from '../../components/ui/resource-resolver';

import { ResourceLocker } from '../common/resource-lock';
import { disposeOf } from '../../components/dispose';
import { lockUIandExecute } from '../../ui-lock';

import { notificationSignal } from '../../components/ui/icons/icon-notification-signal';

export class AppResourcePage extends PageBase implements DataEntryOwner {
  internalId: string = getInternalId();
  private _retainLock = false;
  @state()
  protected modalEditor: ModalViewBase | null = null;
  protected lock?: ResourceLocker;
  @property({ type: Number })
  protected renderCount = 0;
  @property({ type: Boolean })
  protected resourceChangeEventTriggered = false;

  private _eventCancel: EventCancellation = new EventCancellation();
  private _resourceChangeEvent = createStagedEvent({
    testInterval: 500,
    cancelToken: this._eventCancel,
    threshold: defaultStagedThreshold,
    eventFinally: () => notificationSignal(false),
    event: async () => {
      this._retainLock = this.lock !== undefined && !this.lock.isLockOwner;

      await this.resourceRefreshing();

      await this.acquireResource(this.resourceId);
      //  await this.allowEnter();

      await this.setActiveTabByHash();

      this.resourceChangeEventTriggered = false;
    },
    eventTriggered: () => {
      this.resourceChangeEventTriggered = true;
      notificationSignal(true);
    }
  });
  @state()
  otherUpdatesOccurring = 0;
  private resourceChangeEvent = async (e: Event) => {
    e.stopImmediatePropagation();
    this._resourceChangeEvent();
  };
  private otherUpdatesOccurringEvent = async (e: Event) => {
    const ev = e as CustomEvent<boolean>;
    e.stopImmediatePropagation();
    if (ev.detail) this.otherUpdatesOccurring++;
    else this.otherUpdatesOccurring--;
  };

  protected async resourceRefreshing() {
    //throw new Error('Method not implemented.');
  }
  constructor() {
    super();
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('resource-changed', this.resourceChangeEvent);
    this.addEventListener('related-changed', this.otherUpdatesOccurringEvent);
  }

  disconnectedCallback() {
    this._eventCancel.cancel();
    super.disconnectedCallback();
    this.removeEventListener('resource-changed', this.resourceChangeEvent);
    this.removeEventListener('related-changed', this.otherUpdatesOccurringEvent);
  }

  protected get resourceId(): string {
    const l = this.location;
    const p = l.params as any;
    return p.id ?? '';
  }

  protected get view(): DataEntryViewController | null {
    //return this.modalEditor?.view ?? null;
    return this.modalEditor?.viewController ?? null;
  }

  private get elementId(): string {
    return `resourceeditor-${this.internalId}`;
  }

  async createModalEditor(_id: string): NullPromise<ModalViewBase> {
    throw new Error('Method not implemented.');
  }

  async allowEnter(): Promise<boolean> {
    if (!validId(this.resourceId)) return false;

    return (
      (await lockUIandExecute(async () => {
        return await this.acquireResource(this.resourceId);
      })) ?? false
    );
  }

  async tryClose(): Promise<boolean> {
    const result = (await this.modalEditor?.tryClose()) ?? true;
    if (result) {
      const url = '/';
      goStaticURL(url);
    }
    return result;
  }

  async forceClose(): Promise<boolean> {
    const url = '/';
    goStaticURL(url);
    return true;
  }

  protected forcedReadonly = false;
  protected forcedReadonlyMsg = '';
  render() {
    if (!this.loggedIn) return html``;
    const nameTemplate = isEmptyOrSpace(this.lock?.lockOwnerEmail)
      ? this.lock?.lockOwnerName
      : html`<a href="mailto:${this.lock?.lockOwnerEmail}">${this.lock?.lockOwnerName}</a>`;
    const isUnknownOwner = isEmptyOrSpace(this.lock?.lockOwnerName) && !this.lock?.isLockOwner;
    const lockMsg = this.forcedReadonly
      ? html`${tlang`Readonly`} ${this.forcedReadonlyMsg}`
      : this.lock?.isLockOwner
        ? tlang`Editing Mode`
        : isUnknownOwner
          ? html`${tlang`Readonly`}`
          : html`${tlang`Readonly - Currently in use by `}${nameTemplate}`;

    const lockMsgClass =
      this.lock?.isLockOwner && !this.forcedReadonly ? tlang`resource-lock-editing` : tlang`resource-lock-locked`;
    const lockedUserTemplate = html`<div class="badge resource-lock-owner ${lockMsgClass}">
      <i class="fa-solid fa-user-lock"></i>
      <span class="ms-1">${lockMsg}</span>
    </div>`;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">
          <div class="header">
            <h2 class="title">${this.view?.getTitle()}${lockedUserTemplate}</h2>
          </div>
          ${this.view?.ui}
        </div>
      </div>
    `;
  }

  async userReset() {
    await this.dispose(); //override
  }

  async dispose(): Promise<void> {
    try {
      await super.dispose();
      await disposeOf(this.view);
      this.modalEditor = null;
    } catch {
      //
    }
    try {
      if (this._retainLock) return;
      await this.lock?.release();
      this.lock = undefined;
    } catch {
      //
    }
  }

  protected createLock(_id: string): ResourceLocker | undefined {
    return undefined;
  }

  protected async canLeavePage(): Promise<boolean> {
    return this.modalEditor?.canClose() ?? true;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async afterUserConnected() {
    if (!this.lock) if (!(await this.allowEnter())) await this.forceClose();
  }

  private async acquireResource(id: string): Promise<boolean> {
    if (!this._retainLock || !this.lock) {
      this.lock = this.createLock(id);
    } else {
      console.log('holding existing lock ');
    }
    const keepCurrentLock = this._retainLock;
    this._retainLock = false;

    if (!this.lock) return false;

    if (keepCurrentLock || (await this.lock.lock())) {
      try {
        const newEditor = await this.createModalEditor(id);
        await this.setActiveTabByHash(newEditor?.viewController);
        this.modalEditor = newEditor;
      } catch (error) {
        await this.lock.release();
        await showDevelopmentError(error as Error);
        return false;
      }
      if (this.view) {
        this.view.owner = this;
        return true;
      } else await this.lock.release();
    }
    return false;
  }

  protected async awaken() {
    await this.setActiveTabByHash();
    await super.awaken();
  }

  private async setActiveTabByHash(aView?: DataEntryViewController) {
    await (aView ?? this.view)?.setActiveTabByHash();
  }
}
